.personal-information__container {
    padding: 30px;
    width: 800px;
    margin-bottom: 20px;
}

.personal-information__main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.personal-information__form-fields {
    display: flex;
    flex-direction: column;
}


.personal-information__form-row {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media (min-width: 480px) {
    .personal-information__form-row {
        flex-direction: row;
    }
}

.personal-information__form-row-picker {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.personal-information__row-element {
    width: 100%;
}

.personal-information__date-picker {
    width: 100%;
}

@media (min-width: 480px) {
    .personal-information__date-picker {
        width: 50%;
    }
}

.personal-information__city {
    display: flex;
    flex-direction: column;
    margin: 20px 0 5px 0;
    width: 100%;
}

@media (min-width: 480px) {
    .personal-information__city {
        flex-direction: column;
        margin: 20px 10px 8px 0;
    }
}

.personal-information__city-postal-code {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: -10px;
}

@media (min-width: 480px) {
    .personal-information__city-postal-code {
        flex-direction: row;
    }
}

@media (min-width: 480px) {
    .personal-information__spaced-text {
        margin-right: 15px !important;
    }
}

.personal-information__heading {
    margin-top: 40px;
    margin-bottom: 0;
}