body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.content {
  position: relative;
  top: 45px;
  width: 90%;
  margin: 0 auto;
  overflow: hidden;
}

.class-card__icon-splitter {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.class-card__ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;
    display: none;
  }
  .class-card__ribbon-wrapper .class-card__ribbon {
    font: bold 15px sans-serif;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 15px;
    left: -30px;
    width: 120px;
    background-color: #bc9b6a;
    color: #fff;
  }

.class-card__selected {
    display: block;
}

.class-card__term-price {
    position: relative;
    top: 5px;
    font-size: 12px;
    font-weight: bold;
}

.class-card__card-container {
    margin: 5px;
}

.hide {
    display: none;
}

.show {
    display: block;
    text-align: center;
}


.hideTerms {
    display: none;
}
.showTerms {
    display: block;
}
.class-filter__filter-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.class-filter__filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 0 5px 0;
}

.class-filter__filter {
    width: 230px;
}

.class-filter__select {
    width: 300px;
    text-align: left;
}

.class-filter__paper {
    width: 320px;
    position: relative;
    margin: 0 auto;
    padding: 10px;
}

.credit-card__expiration-date {
    margin-top: 20px;
    margin-bottom: -15px;
}

.credit-card__field-container {
    display: flex;
    align-items: flex-end;
    min-width: 400px;
}

.credit-card__field-container-icon {
    padding-bottom: 13px;
    margin-right: 5px;
}

.credit-card__message {
    margin-top: 15px;
    font-size: 12px;
    color:#666;
}

.liability-waiver__waiver-form {
    display: flex;
    flex-direction: column;
}

.liability-waiver__acceptable-term {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: initial;
    margin: 10px 0;
}

.liability-waiver__acceptable-term span {
    display: flex;
    align-items: center;
}

.sigCanvas {
    background-color: #eee;
    border: 1px solid black;
}
.information-review__paper_container {
    padding: 30px;
    width: 285px;
    margin-bottom: 20px;

}
@media (min-width: 600px) {
    .information-review__paper_container {
        width: 500px;
        margin-right: 15px;
    }
}

.signature__container {
}
.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #000;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @-webkit-keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.financial-information__acceptable-term {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: initial;
    margin: 10px 0;
}

.financial-information__acceptable-term span {
    display: flex;
    align-items: center;
}
.personal-information__container {
    padding: 30px;
    width: 800px;
    margin-bottom: 20px;
}

.personal-information__main-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.personal-information__form-fields {
    display: flex;
    flex-direction: column;
}


.personal-information__form-row {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media (min-width: 480px) {
    .personal-information__form-row {
        flex-direction: row;
    }
}

.personal-information__form-row-picker {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.personal-information__row-element {
    width: 100%;
}

.personal-information__date-picker {
    width: 100%;
}

@media (min-width: 480px) {
    .personal-information__date-picker {
        width: 50%;
    }
}

.personal-information__city {
    display: flex;
    flex-direction: column;
    margin: 20px 0 5px 0;
    width: 100%;
}

@media (min-width: 480px) {
    .personal-information__city {
        flex-direction: column;
        margin: 20px 10px 8px 0;
    }
}

.personal-information__city-postal-code {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: -10px;
}

@media (min-width: 480px) {
    .personal-information__city-postal-code {
        flex-direction: row;
    }
}

@media (min-width: 480px) {
    .personal-information__spaced-text {
        margin-right: 15px !important;
    }
}

.personal-information__heading {
    margin-top: 40px;
    margin-bottom: 0;
}

.stepper {
    display: none;
}

@media (min-width: 480px) {
    .stepper {
        display: block;
        text-align: center;
    }
}

.navigator {
    margin-bottom: 100px;
}

.mobile-stepper {
    display: block;
}

@media (min-width: 480px) {
    .mobile-stepper {
        display: none;
    }
}

.registration__class-finder {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
}

@media (min-width: 480px) {
    .registration__class-finder {
        margin-top: 0;
    }
}

.registration__spinner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.hide {
    display: none;
}

.all-done__container a{
    color: #bc9b6a;
}

.class-card__icon-splitter {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.class-card__ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;
    display: none;
  }
  .class-card__ribbon-wrapper .class-card__ribbon {
    font: bold 15px sans-serif;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 7px 0;
    top: 15px;
    left: -30px;
    width: 120px;
    background-color: #bc9b6a;
    color: #fff;
  }

.class-card__selected {
    display: block;
}

.class-card__term-price {
    position: relative;
    top: 5px;
    font-size: 12px;
    font-weight: bold;
}

.class-card__card-container {
    margin: 5px;
}

.hide {
    display: none;
}

.show {
    display: block;
    text-align: center;
}

.class-filter__filter-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.class-filter__filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 0 5px 0;
}

.class-filter__filter {
    width: 230px;
}

.class-filter__select {
    width: 300px;
    text-align: left;
}

.class-filter__paper {
    width: 320px;
    position: relative;
    margin: 0 auto;
    padding: 10px;
}

.financial-information__acceptable-term {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: initial;
    margin: 10px 0;
}

.financial-information__acceptable-term span {
    display: flex;
    align-items: center;
}
.stepper {
    display: none;
}

@media (min-width: 480px) {
    .stepper {
        display: block;
        text-align: center;
    }
}

.navigator {
    margin-bottom: 100px;
}

.mobile-stepper {
    display: block;
}

@media (min-width: 480px) {
    .mobile-stepper {
        display: none;
    }
}

.registration__class-finder {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
}

@media (min-width: 480px) {
    .registration__class-finder {
        margin-top: 0;
    }
}

.registration__spinner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.hide {
    display: none;
}

.all-done__container a{
    color: #bc9b6a;
}

.navbar-bar {
    width: 100%;
    min-height: 30px;
    background-color: #eee;
    padding: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    z-index: 1;
}

@media (min-width: 768px) {
    .navbar-bar {
        flex-direction: row;
    }
}

.navbar-anchor {
    display: flex;
    flex-direction: row;
}

.navbar-logo img {
    max-height: 30px;
}

@media (min-width: 768px) {
    .navbar-logo img {
        padding-left: 10px;
    }
}

.navbar-hamburger {
    display: inline;
    margin: 5px 15px 0 5px;
}

@media (min-width: 768px) {
    .navbar-hamburger {
        display: none;
    }
}

.navbar-menu {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 5px;
    -webkit-transition: max-height 1s;
    transition: max-height 1s;
}

@media (min-width: 768px) {
    .navbar-menu {
        flex-direction: row;
    }
}

.navbar-menu.hidden {
    display: none;
}

@media (min-width: 768px) {
    .navbar-menu.hidden {
        display: flex;
    }
}

.navbar-menu-link {
    
    margin: 10px 0px 10px 0;
}

@media (min-width: 768px) {
    .navbar-menu-link {
        display: flex;
        margin: 0 20px 0 20px;
    }
}

.navbar-menu-link a {
    color: #bc9b6a;
    text-decoration: none;
}

.navbar-menu-link a:hover {
    color: #9e7b47;
}

.hamburger-hide {
    display: none;
}
