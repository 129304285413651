.stepper {
    display: none;
}

@media (min-width: 480px) {
    .stepper {
        display: block;
        text-align: center;
    }
}

.navigator {
    margin-bottom: 100px;
}

.mobile-stepper {
    display: block;
}

@media (min-width: 480px) {
    .mobile-stepper {
        display: none;
    }
}

.registration__class-finder {
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
}

@media (min-width: 480px) {
    .registration__class-finder {
        margin-top: 0;
    }
}

.registration__spinner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.hide {
    display: none;
}

.all-done__container a{
    color: #bc9b6a;
}
